import loggedInUserBootstrap from "./logged-in-user.bootstrap.js";
import tenantBootstrap from "./tenant.bootstrap.js";
import isUserLoggedIn from "./is-user-logged-in.bootstrap.js";

export default function authInfoBootstrap() {
  return isUserLoggedIn().then((sessionIsValid) => {
    // Each method handles the invalid session case itself
    // Each method needs to crate a subscription even if the user ins't logged in
    return Promise.all([
      loggedInUserBootstrap(sessionIsValid),
      tenantBootstrap(sessionIsValid),
    ]);
  });
}
