import { asyncJs } from "./async-resources.bootstrap.js";

export default function bootstrapGAPI() {
  return new Promise((resolve, reject) => {
    asyncJs(`https://accounts.google.com/gsi/client`, {
      async: true,
      onLoad: () => resolve(),
    });
  });
}
