import delightedSurveyBootstrap from "./delighted-survey.bootstrap";
import intercomBootstrap from "./intercom.bootstrap.js";
import sentryBootstrap from "./sentry.bootstrap.js";
import sofeInspectorBootstrap from "./sofe-inspector.bootstrap.js";
import gapiBootstrap from "./gapi.bootstrap.js";
import rootRouteBootstrap from "./root-route.bootstrap.js";
import perfMetricsBootstrap, {
  waitForEvent,
} from "./perf-metrics.bootstrap.js";
import authInfoBootstrap from "./auth-info.bootstrap.js";
import { fullStoryIdentify } from "./full-story-identify.bootstrap";
import "./globalizer.helper.js";
import { onLogoutPage } from "../child-app-active.functions.js";
import { dataDogRum } from "./data-dog-rum.bootstrap";
import mailtoListener from "./mailto-listener.bootstrap.js";
import appTitleListener from "./app-title-listener.bootstrap";
import bannerNotificationsBootstrap from "./banner-notifications.bootstrap";
import crmMigrationBootstrap from "./crm-migration.bootstrap.js";
import { handleError } from "../handle-error.js";

const appMounted = waitForEvent("single-spa:first-mount");

export default function bootstrapSpa() {
  if (onLogoutPage(window.location)) {
    // set listener on hash change so that when we load a page besides logout
    // we can start start the normal boostrap promises
    window.addEventListener(
      "single-spa:routing-event",
      logoutPageEventListener
    );
    // If we're on the logout page we want to call the callback (which eventually singleSpa.start())
    // WITHOUT running any of the bootstrap promises
    return Promise.resolve();
  } else {
    // Assuming we're not initializing on a page besides the logout page we want
    // to call all the boostrap promises both blocking and non-blocking
    return bootstrapPromises();
  }

  function logoutPageEventListener() {
    // An event listener that will call the bootstrap promises when the page isn't /#/logout
    if (!onLogoutPage(window.location)) {
      // removing the event listener. We never want to fire the bootstrap methods twice
      window.removeEventListener(
        "single-spa:routing-event",
        logoutPageEventListener
      );
      bootstrapPromises().catch(handleError);
    }
  }
}

function bootstrapPromises() {
  let appFailure = false;

  const blockingPromises = [authInfoBootstrap(), rootRouteBootstrap()];

  const nonBlockingPromises = [
    Promise.resolve(sentryBootstrap()),
    sofeInspectorBootstrap(),
    gapiBootstrap(),
    perfMetricsBootstrap(),
    Promise.resolve(delightedSurveyBootstrap()),
    Promise.resolve(dataDogRum()),
    Promise.resolve(mailtoListener()),
    Promise.resolve(appTitleListener()),
    Promise.resolve(bannerNotificationsBootstrap()),
    Promise.resolve(crmMigrationBootstrap()),
    appMounted.then(() =>
      new Promise((resolve) => setTimeout(resolve, 3000)).then(() =>
        Promise.all([
          // third party tracking bootstraps
          fullStoryIdentify(),
          intercomBootstrap(),
        ])
      )
    ),
  ];

  Promise.all(nonBlockingPromises)
    .then(() => {
      console.info(
        "The non-blocking bootstrap process for app loader has completed"
      );
    })
    .catch((err) => {
      if (appFailure) return; // Error is already reported

      nonBlockingPromises[0] // Make sure sentry is initialized
        .then(() => handleError(err))
        .catch(() => handleError(err)); // Sentry will be initialized even in an error caused by loggedInUser failing
    });

  return Promise.all(blockingPromises).catch((err) => {
    appFailure = true;
    err.message = "Unable to bootstrap app\n" + err.message;

    return nonBlockingPromises[0] // Make sure sentry is initialized
      .then(() => handleError(err))
      .catch(() => handleError(err)); // Sentry will be initialized even in an error caused by loggedInUser failing
  });
}
