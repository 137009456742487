export default function () {
  if (window.localStorage.getItem("sofe-inspector")) {
    const intercomImpact = 80;

    const iconPositionLeft = window.innerWidth - 70 - intercomImpact;
    const iconPositionTop = window.innerHeight - 70;

    return SystemJS.import("sofe-inspector!sofe").then((inspector) =>
      (inspector.default || inspector)({ iconPositionLeft, iconPositionTop })
    );
  } else return Promise.resolve();
}
