// opts.onLoad - Callback for when the script has loaded
// opts.async - Enables the async attribute for the script tag
export function asyncJs(url, opts = {}) {
  var el = document.createElement("script");
  el.setAttribute("src", url);
  el.setAttribute("type", "text/javascript");
  el.async = !!opts.async;
  el.onload = opts.onLoad;
  var head = document.getElementsByTagName("head")[0];
  return head.appendChild(el);
}
