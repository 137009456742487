import { setUser, init } from "error-logging!sofe";
import Auth from "cp-client-auth!sofe";
export default function () {
  if (!window.nodeBootstrapServerVersion) {
    throw new Error(
      `Cannot initialize sentry without window.nodeBootstrapServerVersion`
    );
  }

  init(`app-loader-ui_${window.nodeBootstrapServerVersion}`);

  /* This observable never should call onCompleted() or onError(), so normally we would need to dispose of our subscription
   * when we are done with it to avoid memory leaks. However, in this case we are never done with it until the page reloads
   * and the memory is cleaned up anyway.
   */
  Auth.getLoggedInUserAsObservable().subscribe((user) => setUser(user));
}
