import Auth from "cp-client-auth!sofe";
import { handleError } from "../handle-error";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

export async function fullStoryIdentify() {
  forkJoin([
    Auth.getLoggedInUserAsObservable().pipe(take(1)),
    Auth.getTenantAsObservable().pipe(take(1)),
  ]).subscribe(([loggedInUser, tenant]) => {
    if (window.FS) {
      const { id, email, name, tenantId, licenses } = loggedInUser;
      const { crm_status } = tenant;
      window.FS.identify(id, {
        email,
        displayName: name,
        tenantId,
        licenses,
        crm_hierarchy: crm_status === "crm_hierarchy_complete", // TODO: remove this when we no longer need to track adoption
      });
    }
  }, handleError);
}
