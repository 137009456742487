import { datadogRum } from "@datadog/browser-rum";
import { captureException } from "error-logging!sofe";
import canopyUrls from "canopy-urls!sofe";

export function dataDogRum() {
  const env = canopyUrls.getEnvironment();
  const mainUrl = canopyUrls.getWebUrl();
  try {
    datadogRum.init({
      applicationId: "f1ff9217-df5c-4f6f-a69a-158428645539",
      clientToken: "pub2052c4def7e90a0242e1fef62a03c32c",
      site: "datadoghq.com",
      env,
      sampleRate: 0,
      trackInteractions: true,
      allowedTracingOrigins: [mainUrl],
      service: "frontend-service",
    });
  } catch (e) {
    captureException(e);
  }
}
