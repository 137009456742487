import { hasPurchasedLicense } from "cp-client-auth!sofe";
import { handleError } from "../handle-error";

export default async function delightedSurveyBootstrap() {
  try {
    if (
      window.delightedNps &&
      window.loggedInUser &&
      window.loggedInUser.role === "TeamMember" &&
      window.tenant &&
      isPayingCustomer(hasPurchasedLicense, window.tenant)
    ) {
      const userCreatedAtIso = new Date(
        window.loggedInUser.created_at
      ).toISOString();
      window.delightedNps.survey({
        email: window.loggedInUser.email,
        name: window.loggedInUser.name,
        createdAt: userCreatedAtIso,
        minTimeOnPage: 60,
        properties: {
          tenant_id: window.tenant.id,
          directory_user_id: window.loggedInUser.id,
          company: window.tenant.company_name,
        },
      });
    }
  } catch (e) {
    handleError(e);
  }
}

function isPayingCustomer(hasPurchasedLicense, tenant) {
  const licenses = tenant.licenses;
  const clientLimit = tenant.client_limit;
  const freeTranscripts = tenant.free_transcripts;
  const isPayingPracticeManagementCustomer = hasPurchasedLicense(
    "practiceManagement",
    licenses,
    clientLimit,
    freeTranscripts
  );
  return (
    isPayingPracticeManagementCustomer ||
    (!!licenses &&
      Object.keys(licenses).some((type) =>
        hasPurchasedLicense(type, licenses, clientLimit, freeTranscripts)
      ))
  );
}
