import { calculatePublicPath } from "canopy-sofe-extensions";
import bootstrapSpa from "./bootstrap/spa-bootstrapper.js";
import * as isActive from "./child-app-active.functions.js";
import * as singleSpa from "single-spa";
import { forkJoin, throwError, from, timer, of } from "rxjs";
import {
  skipWhile,
  first,
  retryWhen,
  mergeMap,
  catchError,
} from "rxjs/operators";
import { onlineListener } from "online-listener";
import { handleError } from "./handle-error";

__webpack_public_path__ = calculatePublicPath("app-loader-ui");

if (window.singleSpa || window.commonDepsReady) {
  delete window.commonDepsReady;
  init();
} else {
  window.addEventListener("app-loader:common-deps-ready", commonDepsReady);
}

function commonDepsReady() {
  window.removeEventListener("app-loader:common-deps-ready", commonDepsReady);
  init();
}

function init() {
  singleSpa.ensureJQuerySupport(window.jQuery);
  /* We want to declare the child applications as soon as possible so that they start loading as soon
   * as possible. However, we don't want their bootstrap or mount functions to be called until after
   * bootstrapSpa is finished.
   */
  [
    // Main-content apps
    ["end-user-forms-ui", isActive.endUserFormsUI],
    ["letters-ui", isActive.lettersUI],
    ["docs-ui", isActive.docsUI],
    ["notifications-ui", isActive.notificationsUI],
    ["communications-ui", isActive.communicationsUI],
    ["clients-ui", isActive.clientsUI],
    ["tasks-ui", isActive.tasksUI],
    ["engagements-ui", isActive.engagementsUI],
    ["resolution-cases-ui", isActive.resolutionCasesUI],
    ["login-ui", isActive.loginUI],
    ["transcripts-ui", isActive.transcriptsUI],
    ["calendar-ui", isActive.calendarUI],
    ["notices-ui", isActive.noticesUI],
    ["billing-ui", isActive.billingUI],
    ["tax-prep-ui", isActive.taxPrepUI],
    ["app-dashboard-ui", isActive.appDashboardUI],
    ["canopy-admin-ui", isActive.canopyAdminUI],
    ["reporting-ui", isActive.reportingUI],
    ["notes-ui", isActive.notesUI],
    ["prototypes-ui", isActive.prototypesUI],
    ["forms-ui", isActive.formsUI],

    // Supplementary apps
    ["primary-navbar", isActive.primaryNavbar],
    ["client-menu", isActive.clientMenu],
    ["resolution-cases-menu", isActive.resolutionCasesMenu],
    ["canopy-urls", isActive.pageNotFound],
    ["global-settings", isActive.globalSettings],
    ["webview-ui", isActive.webviewUi],
  ].forEach(([appName, activityFn]) =>
    singleSpa.registerApplication(
      appName,
      loadAppWhenOnline(appName, () => SystemJS.import(`${appName}!sofe`)),
      activityFn
    )
  );

  singleSpa.registerApplication(
    "400",
    import("./pages/400.js"),
    isActive.fourHundred
  );

  singleSpa.registerApplication(
    "crm-migration-in-progress",
    import("./pages/crm-migration-in-progress.js"),
    isActive.crmMigrationInProgress
  );

  // Mostly the loggedInUser, tenant, and websocketBootstrap
  bootstrapSpa()
    .then(() => {
      window.dispatchEvent(new CustomEvent("cp:app-loader-bootstrapped"));
      singleSpa.start();
      window.dispatchEvent(new CustomEvent("cp:app-loader:single-spa-start"));
    })
    .catch(handleError);
}

function loadAppWhenOnline(name, loader) {
  if (!name) throw new Error("Must have a name passed!");
  if (!loader) throw new Error(`Must have a loader passed for: ${name}`);
  const genericRetryStrategy =
    (
      name,
      options = {
        maxRetryAttempts: 1,
        scalingDuration: 1000,
        excludedStatusCodes: [],
      }
    ) =>
    (attempts) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          SystemJS.delete(SystemJS.normalizeSync(`${name}!sofe`));

          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (
            retryAttempt > options.maxRetryAttempts ||
            options.excludedStatusCodes.find((e) => e === error.status)
          ) {
            return throwError(error);
          }
          // eslint-disable-next-line
          console.log(
            `Attempt ${retryAttempt}: retrying ${name} in ${
              retryAttempt * options.scalingDuration
            }ms`
          );
          // retry after 1s, 2s, etc...
          return forkJoin(
            onlineListener.pipe(first()),
            timer(retryAttempt * options.scalingDuration)
          );
        })
      );
    };
  return () => {
    return onlineListener
      .pipe(
        skipWhile((online) => !online),
        first(),
        mergeMap(() => from(loader())),
        retryWhen(genericRetryStrategy(name)),
        catchError((error) => of(error))
      )
      .toPromise();
  };
}
